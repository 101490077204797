import { getPublicEnvVars } from './lib/env.lib';
import { setupTracker } from '@atreus/tracker';
import { startBrowserErrorTracker } from '@qcx/browser-error-tracker';
import { startRemixClient } from '@qcx/remix';

const env = getPublicEnvVars();

if (env.PUBLIC_APP_ENV !== 'development') {
  startBrowserErrorTracker({
    driver: 'rollbar',
    appEnv: env.PUBLIC_APP_ENV,
    rollbarAccessToken: env.PUBLIC_ROLLBAR_ACCESS_TOKEN,
    rollbarRelease: env.PUBLIC_APP_VERSION
  });
}

setupTracker({
  amplitudeOptions: {
    environment: env.PUBLIC_APP_ENV as PublicEnvVars['PUBLIC_APP_ENV'],
    client: {
      configuration: {
        serverUrl: env.PUBLIC_AMPLITUDE_URL,
        defaultTracking: false
      }
    }
  },
  sourceProject: 'atreus'
});

startRemixClient();
